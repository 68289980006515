<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
    v-if="serverData"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">
          Add New Integration Platform's Order Status
        </h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->

        <form id="createForm" class="py-3 row">
          <div class="h-75px my-0 col-12 col-sm-6">
            <v-select
              dense
              outlined
              item-text="label"
              v-model="formData.internal_status_id"
              :items="serverData.statuses"
              item-name="label"
              item-value="statusId"
              label="Internal Status"
              clearable
              :error-messages="internal_status_idErrors"
              @input="$v.formData.internal_status_id.$touch()"
              @blur="$v.formData.internal_status_id.$touch()"
            ></v-select>
          </div>
          <div class="h-75px my-0 col-12 col-sm-6">
            <v-text-field
              dense
              outlined
              v-model="formData.external_status_id"
              label="External Status"
              required
              clearable
              :error-messages="external_status_idErrors"
              @input="$v.formData.external_status_id.$touch()"
              @blur="$v.formData.external_status_id.$touch()"
            ></v-text-field>
          </div>

          <!--end::Body-->
        </form>

        <!--begin::Actions-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { addItemMessage } from "@/core/constants/globalVariables";
import ApiService from "@/core/services/api.service";

export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      internal_status_id: { required },
      external_status_id: { required },
    },
  },
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      internal_status_id: null,
      external_status_id: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("email" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      ApiService.post("/validations/datahub/integration_order_status/create", {
        integration_id: this.$store.getters.getINTEGRATIONS2IntegrationId,
      })
        .then((response) => {
          this.serverData = response.data;

          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    toggleModal() {
      if (this.dialog) {
        this.resetCreateForm();
        this.serverData = null;
      } else this.loadDataFromServer();
      this.dialog = !this.dialog;
    },

    submitCreateForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.pageLoader(true);
        let data = this.formData;
        data.integration_id = this.$store.getters.getINTEGRATIONS2IntegrationId;
        ApiService.post(
          "/validations/datahub/integration_order_status/store",
          data
        )
          .then(() => {
            Swal.fire({
              title: "Created",
              text: `${addItemMessage}`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        internal_status_id: null,
        external_status_id: null,
      };
    },
  },
  computed: {
    internal_status_idErrors() {
      return this.handleFormValidation("internal_status_id");
    },
    external_status_idErrors() {
      return this.handleFormValidation("external_status_id");
    },
  },
};
</script>
