<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    width="1200"
    :max-width="getWidth"
    :content-class="renderStep !== 1 ? 'h-100' : ''"
    fullscreen
  >
    <!--      content-class="h-100"-->
    <!--      width="1200"-->
    <!--      :max-width="getWidth"-->
    <!--      :fullscreen="renderStep !== 1"-->
    <template v-slot:activator="{ on, attrs }">
      <b-button class="py-2 px-4" size="sm" v-bind="attrs" v-on="on">
        View
      </b-button>
    </template>
    <v-card :class="{ 'h-100': renderStep !== 1 }">
      <div class="bg-white poppins" :class="{ 'h-100': renderStep !== 1 }">
        <!--begin::Modal header-->
        <div class="modal-header py-4 align-center">
          <h4 class="mb-0 font-weight-bolder">Configurations</h4>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="toggleModal"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div class="mx-5 py-7">
          <!--begin::Body-->
          <template v-if="renderStep === 1">
            <v-data-table
              fixed-header
              light
              dense
              :items="configurations"
              :headers="headers"
              :items-per-page="50"
              class="mx-2 mt-0 font-weight-bold"
              mobile-breakpoint="40"
              hide-default-footer
            >
              <template v-slot:progress>
                <div style="display: flex" class="justify-content-center">
                  <v-progress-circular
                    :size="40"
                    :width="6"
                    style="color: deeppink; display: flex"
                    indeterminate
                  ></v-progress-circular>
                </div>
              </template>
              <!--    progress end-->
              <!--    no-data-->
              <template v-slot:no-data> NO DATA HERE! </template>
              <template v-slot:no-results> NO RESULTS HERE! </template>
              <!--    no-data end-->
              <!-- column -->

              <!-- column -->
              <template #item.name="{ value }">
                <div class="h-6 font-size-lg second-text">{{ value }}</div>
              </template>
              <template #item.action="{ item, value }">
                <b-button
                  style="background: #8950fc; color: white"
                  @click="showConfiguration(item, value)"
                  size="sm"
                  variant="primary"
                  >Show</b-button
                >
              </template>
              <!-- column end -->
            </v-data-table>
          </template>
          <template v-if="renderStep === 2">
            <IntegrationOrderStatus
              :integration="item"
            ></IntegrationOrderStatus>
          </template>
          <template v-else-if="renderStep === 3">
            <ReturnConfiguration :integration="item"></ReturnConfiguration>
          </template>
          <!--end::Body-->
        </div>
        <!--end::Modal body-->
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import IntegrationOrderStatus from "@/own/components/datahub/integrations2/IntegrationOrderStatus.vue";
import ReturnConfiguration from "@/own/components/datahub/integrations2/ReturnConfiguration.vue";
import { SET_INTEGRATION_ID } from "@/core/services/store/integrations2.module";

export default {
  name: "ConfigurationPopup",
  props: ["configurations", "item"],
  components: {
    IntegrationOrderStatus,
    ReturnConfiguration,
  },
  data: () => ({
    dialog: false,
    headers: [
      {
        text: "Name",
        value: "name",
        type: "text",
        class:
          "datatable-cell datatable-cell-sort datatable-cell-sorted colHeader font-size-lg text--disabled",
      },
      {
        text: "Action",
        value: "action",
        class:
          "datatable-cell datatable-cell-sort datatable-cell-sorted colHeader font-size-lg text--disabled",
      },
    ],
    // currentStep: 1,
    renderStep: 1,
  }),
  methods: {
    toggleModal() {
      if (this.dialog && this.renderStep !== 1) {
        this.renderStep = 1;
        return;
      }
      // if (this.dialog) {
      //   this.renderStep = 1;
      // }
      this.dialog = !this.dialog;
    },
    showConfiguration(val) {
      this.$store.commit(SET_INTEGRATION_ID, this.item.id);
      if (val.name === "Integration Order Status") {
        this.loadIntegrationComponent(2);
      } else if (val.name === "Return Configuration") {
        this.loadIntegrationComponent(3);
      }
    },
    loadIntegrationComponent(val) {
      this.renderStep = val;
    },
  },
  computed: {
    // isLoading: function () {
    //   return this.$store.getters.getPageLoading;
    // },
    getWidth: function () {
      if (this.renderStep === 1) {
        return 1000;
      }
      return 1600;
    },
  },
  // watch: {
  //   isLoading: function (val) {
  //     if (!val && this.currentStep === 1) {
  //       this.renderStep = 1;
  //     }
  //   },
  // },
};
</script>
